// init base functions
$(window).on('load', function () {
    classOnBody($('main').attr('id'));
    scrollDirection();
    initGettersAndSetters();
    changeTouchClickText();
});
$(window).on('hashchange', function () {
    initGettersAndSetters();
});

// init AOS
if (is.not.ie()) {
    AOS.init();
} else {
    $('*').removeAttr("data-aos");
} 

// nav
if ($('.l-nav').length) {
    var propsNav = {
        active: 'is-active',
        scrollInit: 0,
        scrollClassScrolled: 99,
        scrollClassMiddle: 600,
        body: $('body'),
        nav: $('.l-nav'),
        hamburguer: $('.l-nav .c-hamburguer'),
        contentHero: $('.l-nav__contentHero'),
        menuItens: $('.l-nav-menuItens'),
        mask: $('.l-nav__maskMenu'),
        maskAll: $('.l-nav__maskMenu.maskAll'),
    };

    function checkResponsiveMode() {
        var resultCheck;
        var displayCheck = propsNav.hamburguer.css('display');
        if (displayCheck !== 'none') {
            resultCheck = 'is-responsive';
            propsNav.nav.removeClass('is-desktop');
            propsNav.nav.addClass('is-responsive');
        } else {
            resultCheck = 'is-desktop';
            propsNav.nav.removeClass('is-responsive');
            propsNav.nav.addClass('is-desktop');
        }
        return resultCheck;
    }

    function closeAllSubMenus() {
        $('.l-nav-menuItens').removeClass('is-active');
    }

    function closeResponsiveMenu() {
        propsNav.hamburguer.removeClass(propsNav.active);
        propsNav.contentHero.removeClass(propsNav.active);
    }

    function hideMaskMenu() {
        propsNav.mask.removeClass(propsNav.active);
    }

    function disableOverflow() {
        propsNav.body.addClass('u-overflowBlocked');
    }

    function enableOverflow() {
        propsNav.body.removeClass('u-overflowBlocked');
    }


    // RESPONSIVE MENU
    // - open / close
    propsNav.hamburguer.on('click', function () {
        if ($(this).hasClass(propsNav.active)) {
            // close menu
            enableOverflow();
            $(this).removeClass(propsNav.active);
            propsNav.maskAll.removeClass(propsNav.active);
            propsNav.menuItens.removeClass(propsNav.active);
        } else {
            // open menu
            disableOverflow();
            $(this).addClass(propsNav.active);
            propsNav.maskAll.addClass(propsNav.active);
            propsNav.menuItens.addClass(propsNav.active);
        }
    });
    // - close
    propsNav.maskAll.on('click', function () {
        closeResponsiveMenu();
        hideMaskMenu();
        closeAllSubMenus();
        enableOverflow();
    });


    // RESPONSIVE MODE
    $(window).on('resize', function () {
        if (checkResponsiveMode() === "is-desktop") {
            if (propsNav.contentHero.hasClass(propsNav.active)) {
                closeAllSubMenus();
                closeResponsiveMenu();
                hideMaskMenu();
            }
        }
    });


    // SCROLL CHANGES
    $(window).on('load', function (event) {
        checkResponsiveMode();
        var scrollBody = $(this).scrollTop();
        if (scrollBody > 1) {
            propsNav.nav.addClass('scrolled');
        } else {
            propsNav.nav.removeClass('scrolled');
        }
    });
    $(window).on('scroll', function () {
        var scrollBody = $(this).scrollTop();
        // scroll up to 99
        if (scrollBody > propsNav.scrollClassScrolled) {
            propsNav.nav.addClass('scrolled');
        } else {
            propsNav.nav.removeClass('scrolled');
        }
        // middle class
        if (scrollBody > propsNav.scrollClassMiddle) {
            propsNav.nav.addClass('hidden');
            propsNav.nav.addClass('scrolledMiddle');
        } else {
            propsNav.nav.removeClass('hidden');
            propsNav.nav.removeClass('scrolledMiddle');
        }
        // scroll up or down
        if (scrollBody < propsNav.scrollInit) {
            propsNav.nav.removeClass('hidden');
            propsNav.nav.addClass('scrolledUp');
            propsNav.nav.removeClass('scrolledDown');
        } else {
            propsNav.nav.removeClass('scrolledUp');
            propsNav.nav.addClass('scrolledDown');
        }
        // close menus on hidden nav
        if (propsNav.nav.hasClass('hidden')) {
            closeAllSubMenus();
            hideMaskMenu();
        }
        // reference var
        propsNav.scrollInit = scrollBody;
    });

}

// modules
if ($('.m_whatsApp').length) {
    var wpp__parts = {
        link_mobile: 'http://api.whatsapp.com/',
        link_web: 'https://web.whatsapp.com/',
        phone: 'send?phone=',
        msg: 'text='
    };
    $('.js-openWhatsModule').on('click', function () {
        var wppNumber = $(this).parent('.m_whatsApp').attr("data-whatsNumber");
        openWhatsApp(wppNumber);
    });

    function openWhatsApp(number) {
        if (is.iphone() || is.androidPhone() || is.windowsPhone() || is.blackberry() || is.mobile()) {
            window.open(wpp__parts.link_mobile + wpp__parts.phone + number, '_blank');
        } else {
            window.open(wpp__parts.link_web + wpp__parts.phone + number, '_blank');
        }
    }
}
if ($('#w_ieDetect').length) {
    function showMessageOnIE(warnLevel) {
        // warnLevel = 1 -> Remover aviso
        // warnLevel = 2 -> Recolher aviso
        // warnLevel = 3 -> Bloquear navegação

        var ieDetectEl = $('#w_ieDetect');
        var ieDetectEl__mask = $('#w_ieDetect__mask');
        var ieDetectEl__unlock = $('.w_ieDetect__unlockSite');

        if (is.ie()) {
            if (warnLevel === 1) {
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__unlock.on('click', function () {
                    ieDetectEl.remove();
                    ieDetectEl__mask.remove();
                    $('body').css('overflow-y', 'auto');
                    $('body').css('overflow-x', 'hidden');
                });
            }
            if (warnLevel === 2) {
                //block overflow and show modal
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');

                // hide modal and mask
                ieDetectEl__unlock.on('click', function () {
                    $(this).remove();
                    ieDetectEl__mask.remove();
                    ieDetectEl.removeClass('is-visible');
                    ieDetectEl.addClass('is-fixed');
                    $('body').css('overflow-y', 'auto');
                    $('body').css('overflow-x', 'hidden');
                });
            }
            if (warnLevel === 3) {
                $('nav').remove();
                $('header').remove();
                $('footer').remove();
                $('main').remove();
                ieDetectEl__unlock.remove();
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');
            }
        } else {
            ieDetectEl.remove();
            ieDetectEl__mask.remove();
        }
    }

    $(window).on('load', showMessageOnIE(3));
}
if ($('.auxElements__grid').length) {
    $('.auxElements__controls .toggleGrid').on('click', function () {
        $('.auxElements__controls .toggleGrid').toggleClass('is-active');
        $('.auxElements__grid').toggleClass('u-dnone').toggleClass('is-active');
        $('.auxElements__grid .line').toggleClass('is-active');
    });
}
if ($('.auxElements__controls').length) {
    function identifyScroll() {
        returnScroll = 1;
        if ($('body').hasClass('scrolledDown')) {
            returnScroll = 'Down';
        } else if ($('body').hasClass('scrolledUp')) {
            returnScroll = 'Up';
        } else {
            returnScroll = 'Static';
        }
        return returnScroll;
    }

    function tasksAuxVars() {
        $('.auxElements__controls .scrollEfetuado').html(Math.round($(window).scrollTop()));
        $('.auxElements__controls .scrollDirection').html(identifyScroll());
        $('.auxElements__controls .viewportWidth').html(Math.round($(window).width()));
        $('.auxElements__controls .viewportHeight').html(Math.round($(window).height()));
        $('.auxElements__controls .navHeight').html(Math.round(get__navHeight()));
    }

    $(document).on('ready', function () {
        tasksAuxVars();
    });
    $(window).on('load', function () {
        tasksAuxVars();
    });
    $(window).on('resize', function () {
        tasksAuxVars();
    });
    $(window).on('scroll', function () {
        tasksAuxVars();
    });
}

var swiperProfissionais = new Swiper(".swiper__profissionais", {
    loop: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev", 
    },
});

if ($('.swiper__profissionais ').length) {
    ($('.profissionais__moreContent')).on('click', function (e) {
        e.preventDefault();
        ($('.profissionais__content__text__close')).toggleClass('d-none');
        ($('#assetMoreContent')).addClass('less');

    })
} 

var swiperConvenios = new Swiper(".swiper__institucional", {
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    }

});

var swiperParceiros = new Swiper(".swiper__parceiros", {
    slidesPerView: 3,
    grid: {
        rows: 1,
    },
    spaceBetween: 0,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    }
});

if ($('.s-agendamento').length) {
    ($('.s-agendamento__tipoFormulario__button')).on('click', function () {
        var tipo = $(this).attr('data-tipo');
        if ($(this).hasClass('is-active')) {
            $('.s-agendamento__formulario').removeClass('d-none');
            $('.s-agendamento__tipoFormulario__button').removeClass('is-active');
        } else {
            $('.s-agendamento__tipoFormulario__button').removeClass('is-active');
            $(this).addClass('is-active');
            $('.s-agendamento__formulario').addClass('d-none');
            $('.' + tipo).removeClass('d-none');
        }
        if (tipo === 'formEmail') {
            $('.s-agendamento__tipoFormulario').addClass('goRight');
        } else {
            $('.s-agendamento__tipoFormulario').removeClass('goRight');
        }
    })
}

$(document).on("change", ".radioEspecialidade", function () {
    var e = $(this);
    var valor = e.val();
    if (e.is(":checked")) {
        $('.segundaParteForm').removeClass('d-none');
        $('.divSelect').addClass('d-none');
        $('.' + valor).removeClass('d-none');
    }

})

var swiperGaleriaClinica = new Swiper(".swiper__galeriaClinica", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});


if ($('.s-subEspecialidades__toggleList').length) {
    $('.s-subEspecialidades__toggleList__header').on('click', function () {
        $(this).parent().toggleClass('is-active');
    });
}
if ($('.s-telemedicina__toggleList').length) {
    $('.s-telemedicina__toggleList__header').on('click', function () {
        $(this).parent().toggleClass('is-active');
    });
}

var swiperFormacao = new Swiper(".swiper__formacao", {
    slidesPerView: "auto",
    spaceBetween: 15,
    loop: false,
    autoHeight: false
});

if ($('#page__unidades ').length) {
    var swiperUnidade = new Swiper(".swiper__unidades", {
        slidesPerView: 5,
        spaceBetween: 20,
        loop: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            // when window width is >= 320px
            800: {
                slidesPerView: 1,
            },
            1000: {
                slidesPerView: 3,
            }
        },
    });
    ($('.s-unidade__pics.criciuma')).addClass('d-none');
    ($('.s-unidade__pics.orleans')).addClass('d-none');
    ($('.moreContent')).on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('criciuma')) {
            ($('.s-unidade__pics.criciuma')).toggleClass('d-none');
        } else {
            ($('.s-unidade__pics.orleans')).toggleClass('d-none');
        }

    })
}


var swiperCategorias = new Swiper(".swiper__categorias", {
    slidesPerView: "auto",
    spaceBetween: 8,
    loop: false,
});
if ($('.swiper__categorias ').length) {
    $(($('.swiper__categorias').children("ul")).children("li")).addClass('swiper-slide');
}


var swiper = new Swiper(".swiper__maisAcessados", {
    slidesPerView: "auto",
    spaceBetween: 10,
    width: 350,
});

$('.assetDivisor').on('click', function (e) {
    e.preventDefault();
    const x = $(this).offset();
    const heightElement = x.top;
    $('html, body').animate({
        scrollTop: (heightElement)
    }, 500);
});



$('.s-linksHead__search .searchActiveInput').on('click', function (e) {
    e.preventDefault();
    $('.s-linksHead__search').addClass('is-active');
    $(document).mouseup(function (e) {
        var container = $(".s-linksHead__search");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.removeClass('is-active');
        }
    });
});


if ($('.s-products').length) {
    $('.btn_filter').on('click', function () {      
        var btn = $(this);
        var tID;
        var termID = btn.attr('data-term');
            //console.log(termID);
        /*if (btn.hasClass('is-active')) {
            btn.removeClass('is-active');
        } else {
            //$('.btn_filter').removeClass('is-active');
            btn.addClass('is-active');
            $('.card_products.'+termID).removeClass('d-none');
        }*/
        $('.card_products').addClass('d-none');
        if (btn.hasClass('is-active')) {
            btn.removeClass('is-active');  
            $('.card_products').removeClass('d-none');
        }else{    
            $('.btn_filter').removeClass('is-active');      
            btn.addClass('is-active');
            $('.card_products.'+termID).removeClass('d-none');
        }

        if($('.btn_filter.is-active').length){
            $('.card_products').addClass('d-none');
            
            $('.btn_filter').each(function(){
                if ($(this).hasClass('is-active')) {
                    tID = $(this).attr('data-term')
                    $('.card_products.'+tID).removeClass('d-none');
                }
            });
        }else{
            $('.card_products').removeClass('d-none');
        }

    })
}
$('.initGalery').on('click', function () {  
    var gallery = $(this).attr('data-gallery');
    $.fancybox.open( $('[data-fancybox="' + gallery + '"]'));
})